var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Auth row row--justify-center" }, [
    _c(
      "div",
      { staticClass: "Auth__inputs column column--width-auto" },
      [
        _c("img", {
          staticClass: "Auth__signin-logo mb-30",
          attrs: {
            alt: "Bobtail logo of the word 'Bobtail'",
            src: require("../../assets/logo.png")
          }
        }),
        _c("label", { staticClass: "fs-18 fw-med mb-18" }, [
          _vm._v("Set Password")
        ]),
        _c("base-input", {
          staticClass: "mb-12 width-100",
          attrs: {
            disabled: true,
            type: "email",
            value: this.cognitoUser.username
          }
        }),
        _c(
          "base-input",
          {
            staticClass: "mb-12 width-100",
            attrs: {
              instructions: _vm.instructions.PASSWORD_INSTRUCTION,
              label: true,
              "label-for": "new-password",
              placeholder: "New Password",
              type: "password",
              valid: _vm.passwordValid
            },
            model: {
              value: _vm.password,
              callback: function($$v) {
                _vm.password = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "password"
            }
          },
          [_vm._v("\n      Password\n    ")]
        ),
        _c(
          "base-input",
          {
            staticClass: "mb-12 width-100",
            attrs: {
              instructions: _vm.instructions.PASSWORD_VERIFICATION_INSTRUCTION,
              label: true,
              "label-for": "password-verification",
              placeholder: "Enter password again",
              type: "password",
              valid: _vm.passwordVerificationValid
            },
            model: {
              value: _vm.passwordVerification,
              callback: function($$v) {
                _vm.passwordVerification =
                  typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "passwordVerification"
            }
          },
          [_vm._v("\n      Password Verification\n    ")]
        ),
        _c(
          "base-button",
          {
            staticClass: "bg-blue fc-white width-100",
            attrs: { id: "Testing__SetPasswordButton" },
            on: {
              click: _vm.adminCreatedSignUp,
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.adminCreatedSignUp($event)
              }
            }
          },
          [_vm._v("\n      Set Password\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }