<template>
  <div class="Auth row row--justify-center">
    <div class="Auth__inputs column column--width-auto">
      <img
        alt="Bobtail logo of the word 'Bobtail'"
        class="Auth__signin-logo mb-30"
        src="../../assets/logo.png"
      >
      <label class="fs-18 fw-med mb-18">Set Password</label>
      <base-input
        class="mb-12 width-100"
        :disabled="true"
        :type="'email'"
        :value="this.cognitoUser.username"
      />
      <base-input
        v-model.trim="password"
        class="mb-12 width-100"
        :instructions="instructions.PASSWORD_INSTRUCTION"
        :label="true"
        :label-for="'new-password'"
        :placeholder="'New Password'"
        :type="'password'"
        :valid="passwordValid"
      >
        Password
      </base-input>

      <base-input
        v-model.trim="passwordVerification"
        class="mb-12 width-100"
        :instructions="instructions.PASSWORD_VERIFICATION_INSTRUCTION"
        :label="true"
        :label-for="'password-verification'"
        :placeholder="'Enter password again'"
        :type="'password'"
        :valid="passwordVerificationValid"
      >
        Password Verification
      </base-input>

      <base-button
        @click="adminCreatedSignUp"
        @keydown.enter="adminCreatedSignUp"
        class="bg-blue fc-white width-100"
        id="Testing__SetPasswordButton"
      >
        Set Password
      </base-button>
    </div>
  </div>
</template>

<script>
// Packages
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js'
// Components
import BaseButton from '../../components/base-button.vue'
import BaseInput from '../../components/base-input.vue'
// Helpers
import getCognitoInfo from '../../utils/constants'
// Mixins
import {
  ValidationMixin
} from '../../utils/validation-mixin'

const { UserPoolId, ClientId } = getCognitoInfo()

export default {
  name: 'SetPassword',

  props: {
    email: {
      type: String,
      required: false,
      default: null
    },
  },

  components: {
    BaseButton,
    BaseInput,
  },

  mixins: [ValidationMixin],

  created () {
    this.cognitoUser = new CognitoUser({
      Username: decodeURIComponent((new URL(window.location.href)).searchParams.get('email')),
      Pool: new CognitoUserPool(this.poolData),
    })
  },

  mounted () {
    if (typeof window === 'object') {
      window.addEventListener('keyup', this.enterBtnEventListener)
    }
  },

  beforeDestroy() {
    if (typeof window === 'object') {
      window.removeEventListener('keyup', this.enterBtnEventListener)
    }
  },

  data () {
    return {
      cognitoUser: null,
      poolData: {
        UserPoolId,
        ClientId
      },
      password: '',
      passwordValid: true,
      passwordVerification: '',
      passwordVerificationValid: true
    }
  },

  methods: {
    adminCreatedSignUp () {
      if (!this.validation()) return

      this.submitButtonDisabled = true
      this.progressStart()

      const authenticationDetails = new AuthenticationDetails({
        Password: decodeURIComponent((new URL(window.location.href)).searchParams.get('password')),
        Username: this.cognitoUser.username,
      })
      const thisComponent = this

      thisComponent.cognitoUser.authenticateUser(authenticationDetails, {
        // Can pass 'result' in the params of the onSuccess function
        onSuccess: () => {
          // OUTPUTS: auth token
          thisComponent.$router.push({ name: 'invoices' })
          thisComponent.progressFinish()
        },

        onFailure: (error) => {
          thisComponent.CError(error)
          thisComponent.requestFailure({ message: 'There was an issue authenticating this user' })
          thisComponent.captureSentryEvent(
            'Set Password "Admin Signup" Error',
            {
              config: error.config,
              data: thisComponent.$data,
              details: JSON.stringify(error),
              props: thisComponent.$props,
              response: error.response,
            }
          )
          thisComponent.submitButtonDisabled = false
        },

        newPasswordRequired: (userAttributes) => {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // userAttributes: object, which is the user's current profile.
          // It will list all attributes that are associated with the user.
          // Currently, only email is needed
          delete userAttributes.email_verified

          // Required attributes according to schema, which don’t have any values yet,
          // will have blank values.
          // requiredAttributes: list of attributes that must be set by the user along
          // with new password to complete the sign-in.
          // Currently, no required attributes

          // Get these details and call
          // newPassword: password that user has given
          // attributesData: object with key as attribute name and value that the user has given.

          // ??? { onSuccess... } vs this
          thisComponent.cognitoUser
            .completeNewPasswordChallenge(thisComponent.password, userAttributes, {
              // Can pass 'result' in the params of the onSuccess function
              onSuccess: () => {
                // Result = { accessToken: ..., clockDrift: ..., idToken: ..., refreshToken: ...}
                thisComponent.requestSuccess({
                  message: 'Password has been set!', title: 'Authentication Success'
                })
                thisComponent.submitButtonDisabled = false
                thisComponent.$router.push({ name: 'login' })
              },
              onFailure: (error) => {
                thisComponent.CError('challenge error: ', error)
                thisComponent.requestFailure({ message: error.message })
                thisComponent.captureSentryEvent(
                  'New Password "Required" Error',
                  {
                    config: error.config,
                    data: thisComponent.$data,
                    details: error,
                    props: thisComponent.$props,
                    response: error.response,
                  }
                )
                thisComponent.submitButtonDisabled = false
              },
            })
        }
      })
    },

    enterBtnEventListener (e) {
      if (e.keyCode === 13) {
        this.adminCreatedSignUp()
      }
    },

    validation () {
      let valid = true

      if (!this.password || !this.isValidPassword(this.password)) {
        this.passwordValid = false
        valid = false
      }
      else {
        this.passwordValid = true
      }

      if (this.password !== this.passwordVerification) {
        this.passwordVerificationValid = false
        valid = false
      } else {
        this.passwordVerificationValid = true
      }

      return valid
    },
  }
}
</script>

<style lang="sass">
@import '../../styles/auth.sass'
</style>